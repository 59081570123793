<template>
  <v-form ref="form">
    <v-row class="mb-4">
      <v-col>
        <div class="d-flex align-center justify-space-between">
          <h2>{{ $t('customer.customer_detail') }}</h2>
          <v-btn color="primary" @click="saveChange" :loading="submitLoading">{{ $t('general.save') }}</v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- Customer Account -->
    <v-card elevation="0" class="px-6 py-4 mb-8">
      <v-row>
        <v-col>
          <h3>{{ $t('customer.customer_account') }}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-text-field
            type="text"
            :label="$t('customer.customer_id')"
            outlined
            readonly
            hide-details
            v-model="customer_account.id"
            filled
          ></v-text-field>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-text-field
            type="text"
            :label="$t('worker.registration_date')"
            v-model="customer_account.create_date"
            filled
            outlined
            readonly
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-text-field
            type="text"
            :label="$t('customer.email')"
            v-model="customer_account.email"
            outlined
            filled
            readonly
            hide-details
          ></v-text-field>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-select
            :label="$t('worker.media_to_know')"
            :items="customer_account_list_item.media_to_know"
            v-model="customer_account.media_to_know"
            item-text="item-text"
            item-value="item-value"
            hide-details="auto"
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-text-field
            type="text"
            :label="$t('customer.who_is_seeking_support')"
            v-model="customer_account.who_is_seeking_support"
            outlined
            readonly
            filled
            hide-details
          ></v-text-field>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-select
            :items="customer_account_list_item.is_adult"
            :label="$t('customer.are_you_18_or_over')"
            v-model="customer_account.is_adult"
            item-text="item-text"
            item-value="item-value"
            hide-details="auto"
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row v-if="customer_account.who_is_seeking_support == 'other'">
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-text-field
            type="text"
            :label="$t('worker.first_name')"
            outlined
            persistent-hint
            :messages="$t('customer.name_receive_support')"
            v-model="customer_account.support_first_name"
          ></v-text-field>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-text-field
            type="text"
            :label="$t('worker.last_name')"
            outlined
            persistent-hint
            :messages="$t('customer.name_receive_support')"
            v-model="customer_account.support_last_name"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-select
            :label="$t('worker.status')"
            :items="customer_account_list_item.worker_status"
            v-model="customer_account.status"
            item-text="item-text"
            item-value="item-value"
            hide-details
            outlined
          ></v-select>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-select
            :label="$t('worker.approval_status')"
            :items="customer_account_list_item.worker_approval_status"
            :disabled="customer_account.disable_user_approved"
            v-model="customer_account.user_approved"
            item-text="item-text"
            item-value="item-value"
            hide-details
            outlined
          ></v-select>
        </v-col>
      </v-row>
    </v-card>

    <!-- Customer Profile -->
    <v-card elevation="0" class="px-6 py-4 mb-8">
      <v-row class="mb-4">
        <v-col>
          <h3>{{ $t('customer.customer_profile') }}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex">
          <v-file-input
            id="profile_photo"
            v-show="false"
            @change="getUploadFile($event, 'profile_photo')"
            accept="image/*"
            outlined
          ></v-file-input>
          <v-text-field
            type="text"
            outlined
            readonly
            hide-details="auto"
            v-model="customer_profile.profile_pic"
            :label="$t('worker.profile_photo')"
          >
          </v-text-field>
          <v-btn
            color="primary"
            class="ml-6 mt-2"
            depressed
            :loading="submitLoading"
            @click="selectFile('profile_photo')"
          >
            {{ $t('general.upload') }}</v-btn
          >
          <v-btn
            class="ml-4 mt-2"
            color="info"
            depressed
            :loading="submitLoading"
            :disabled="customer_profile.disable_preview"
            @click="previewFile(customer_profile.profile_pic)"
          >
            {{ $t('general.view_detail') }}
          </v-btn>
          <v-btn
            color="error"
            class="ml-4 mt-2"
            depressed
            :disabled="customer_profile.disable_preview"
            @click="removeFile('profile_pic')"
            :loading="submitLoading"
          >
            {{ $t('general.remove') }}</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="4" lg="4" md="4" sm="4" cols="12">
          <v-text-field
            type="text"
            :label="$t('worker.first_name')"
            outlined
            :rules="[FormValidator.required]"
            v-model="customer_profile.first_name"
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col xl="4" lg="4" md="4" sm="4" cols="12">
          <v-text-field
            type="text"
            :label="$t('worker.last_name')"
            outlined
            v-model="customer_profile.last_name"
            :rules="[FormValidator.required]"
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col xl="4" lg="4" md="4" sm="4" cols="12">
          <v-text-field
            type="text"
            :label="$t('worker.nickname')"
            outlined
            hide-details="auto"
            v-model="customer_profile.nickname"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="4" lg="4" md="4" sm="4" cols="12">
          <v-select
            :label="$t('worker.gender')"
            :items="gender_list"
            item-text="item-text"
            item-value="item-value"
            :rules="[FormValidator.required]"
            v-model="customer_profile.gender"
            hide-details="auto"
            outlined
          ></v-select>
        </v-col>
        <v-col xl="4" lg="4" md="4" sm="4" cols="12">
          <v-menu
            v-model="date_modal"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_of_birth"
                :label="$t('worker.date_of_birth')"
                :append-icon="icon.calendar_icon"
                readonly
                outlined
                v-bind="attrs"
                :rules="[FormValidator.required]"
                v-on="on"
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker v-model="date_of_birth" @input="date_modal = false" :max="max_birth_date"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col xl="4" lg="4" md="4" sm="4" cols="12">
          <v-text-field
            type="text"
            :label="$t('worker.mobile_number')"
            outlined
            v-model="customer_profile.mobile"
            :rules="[FormValidator.required, FormValidator.isNumberOnly, FormValidator.mobile]"
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-text-field
            type="text"
            :label="$t('worker.residential_address')"
            outlined
            v-model="customer_profile.residential_addr"
            :rules="[FormValidator.required]"
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-text-field
            type="text"
            :label="$t('worker.postal_address')"
            outlined
            hide-details
            v-model="customer_profile.postal_addr"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>{{ $t('customer.need_assistance_with_medication') }}</h4>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="py-0">
          <v-radio-group row v-model="customer_profile.medication">
            <v-radio :label="$t('general.yes')" :value="true"></v-radio>
            <v-radio :label="$t('general.no')" :value="false"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>{{ $t('customer.type_of_care_need') }}*</h4>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0" xl="6" lg="6" md="6" sm="6" cols="12">
          <div v-for="(care, index) in type_of_care_need_list" :key="index">
            <v-checkbox
              hide-details
              dense
              color="primary"
              :label="care.text"
              :value="care.value"
              v-model="customer_profile.care"
            ></v-checkbox>
            <v-row v-if="care.value == 'Other'" class="mt-0">
              <v-col>
                <v-text-field
                  type="text"
                  outlined
                  hide-details
                  v-model="customer_profile.other_detail"
                  :error="customer_profile.other_detail_err"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <div v-if="customer_profile.care.length == 0" class="mt-4 error_msg">
            {{ $t('error.select_at_least_one') }}
          </div>
        </v-col>
      </v-row>
    </v-card>

    <!-- Participant Detail -->
    <v-card elevation="0" class="px-6 py-4 mb-8">
      <v-row class="mb-4">
        <v-col>
          <div class="d-flex align-center">
            <h3>{{ $t('customer.participant_detail') }}</h3>
            <v-btn class="ml-4" color="accent" depressed small @click="addParticipantDetail">
              {{ $t('general.add') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <div v-for="(participant, index) in participant_detail_list" :key="index">
        <v-row>
          <v-col>
            <div class="d-flex align-center">
              <h4>
                {{ $t('customer.participant_detail') }}
                {{ participant_detail_list.length > 1 ? index + 1 : '' }}
              </h4>
              <v-btn icon color="error" class="ml-4" @click="removeParticipantDetail(index)" v-if="index != 0">
                <v-icon>{{ icon.delete_icon }}</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="index == 0">
          <v-col>
            <v-checkbox
              v-model="participant.same_as_ac"
              hide-details
              dense
              color="primary"
              :label="$t('customer.same_as_ac')"
              value
              input-value="true"
              @change="toggleFirstDetail"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="4" lg="4" md="4" sm="4" cols="12">
            <v-text-field
              type="text"
              :label="$t('worker.first_name')"
              outlined
              v-model="participant.first_name"
              :rules="[FormValidator.required]"
              hide-details="auto"
              :readonly="index == 0 && participant.same_as_ac"
              :filled="index == 0 && participant.same_as_ac"
            ></v-text-field>
          </v-col>
          <v-col xl="4" lg="4" md="4" sm="4" cols="12">
            <v-text-field
              type="text"
              :label="$t('worker.last_name')"
              outlined
              v-model="participant.last_name"
              :rules="[FormValidator.required]"
              :readonly="index == 0 && participant.same_as_ac"
              :filled="index == 0 && participant.same_as_ac"
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col xl="4" lg="4" md="4" sm="4" cols="12">
            <v-select
              :label="$t('worker.gender')"
              :items="gender_list"
              item-text="item-text"
              item-value="item-value"
              v-model="participant.gender"
              :rules="[FormValidator.required]"
              :readonly="index == 0 && participant.same_as_ac"
              :filled="index == 0 && participant.same_as_ac"
              hide-details="auto"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="4" lg="4" md="4" sm="4" cols="12">
            <v-menu
              v-model="participant_detail_date_modal[index]"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="participant_detail_date[index]"
                  :label="$t('worker.date_of_birth')"
                  :append-icon="icon.calendar_icon"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="participant_detail_date[index]"
                @input="participant_detail_date_modal[index] = false"
                :max="max_birth_date"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col xl="4" lg="4" md="4" sm="4" cols="12">
            <v-text-field
              type="text"
              :label="$t('worker.email')"
              outlined
              v-model="participant.email"
              :rules="[FormValidator.required, FormValidator.email]"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col xl="4" lg="4" md="4" sm="4" cols="12">
            <v-text-field
              type="text"
              :label="$t('worker.mobile_number')"
              outlined
              v-model="participant.mobile"
              :readonly="index == 0 && participant.same_as_ac"
              :filled="index == 0 && participant.same_as_ac"
              :rules="[FormValidator.required, FormValidator.isNumberOnly, FormValidator.mobile]"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              :label="$t('worker.residential_address')"
              outlined
              v-model="participant.residential_address"
              :rules="[FormValidator.required]"
              :readonly="index == 0 && participant.same_as_ac"
              :filled="index == 0 && participant.same_as_ac"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              :label="$t('worker.postal_address')"
              outlined
              hide-details
              v-model="participant.postal_address"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              type="text"
              :label="$t('customer.allergies_medical_alert')"
              outlined
              v-model="participant.allergies_medical_alert"
              :rules="[FormValidator.required]"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <!-- Emergency Contacts -->
    <v-card elevation="0" class="px-6 py-4 mb-8">
      <v-row class="mb-4">
        <v-col>
          <div class="d-flex align-center">
            <h3>{{ $t('worker.emergency_contacts') }}</h3>
            <v-btn class="ml-4" color="accent" depressed small @click="addEmergencyContact">
              {{ $t('general.add') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <div v-for="(emergency, index) in emergency_contact_list" :key="index">
        <v-row>
          <v-col>
            <div class="d-flex align-center">
              <h4>
                {{ $t('worker.emergency_contacts') }}
                {{ emergency_contact_list.length > 1 ? index + 1 : '' }}
              </h4>
              <v-btn icon color="error" class="ml-4" @click="removeEmergencyContact(index)" v-if="index != 0">
                <v-icon>{{ icon.delete_icon }}</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              :label="$t('worker.name')"
              outlined
              :rules="[FormValidator.required]"
              hide-details="auto"
              v-model="emergency.name"
            ></v-text-field>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              :label="$t('worker.mobile_number')"
              outlined
              :rules="[FormValidator.required, FormValidator.isNumberOnly, FormValidator.mobile]"
              hide-details="auto"
              v-model="emergency.mobile"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              :label="$t('worker.email')"
              outlined
              :rules="[FormValidator.required, FormValidator.email]"
              hide-details="auto"
              v-model="emergency.email"
            ></v-text-field>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-select
              :label="$t('worker.relationship')"
              :items="relationship_list"
              item-text="item-text"
              item-value="item-value"
              :rules="[FormValidator.required]"
              hide-details="auto"
              v-model="emergency.relationship"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              type="text"
              :label="$t('customer.emergency_response')"
              outlined
              :rules="[FormValidator.required]"
              v-model="emergency.emergency_response"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <!-- Household Detail -->
    <v-card elevation="0" class="px-6 py-4 mb-8">
      <v-row class="mb-4">
        <v-col>
          <h3>{{ $t('customer.household_detail') }}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-select
            :label="$t('customer.smoker_living_house')"
            :items="yes_no_list"
            v-model="household.smokers"
            item-text="item-text"
            item-value="item-value"
            hide-details="auto"
            outlined
          ></v-select>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-select
            :label="$t('customer.equipment_available')"
            :items="yes_no_list"
            v-model="household.equipment_available"
            item-text="item-text"
            item-value="item-value"
            hide-details="auto"
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-select
            :items="pet_list"
            :label="$t('customer.pet')"
            item-text="item-text"
            item-value="item-value"
            hide-details
            outlined
            multiple
            v-model="household.pet"
          ></v-select>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-text-field
            type="text"
            :label="$t('customer.access_information')"
            outlined
            hide-details
            v-model="household.access_info"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-checkbox
            v-model="household.safe_working_env_premises"
            hide-details
            dense
            color="primary"
            :label="$t('customer.safe_working_environment')"
            value
            input-value="true"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-card>

    <!-- Social Needs and Goals -->
    <v-card elevation="0" class="px-6 py-4 mb-8">
      <v-row class="mb-4">
        <v-col>
          <h3>{{ $t('customer.social_needs_goals') }}</h3>
        </v-col>
      </v-row>
      <v-row class="mt-6">
        <v-col xl="6" lg="6" md="6" sm="6" cols="12" class="py-0">
          <h4>{{ $t('worker.first_language') }}</h4>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12" class="py-0">
          <h4>{{ $t('worker.cultural_background') }}</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-select
            :items="language_list"
            :label="$t('worker.first_language')"
            item-text="item-text"
            item-value="item-value"
            v-model="first_language"
            hide-details
            outlined
          ></v-select>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-text-field
            type="text"
            :label="$t('worker.cultural_background')"
            outlined
            v-model="cultural_background"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0">
          <h4>{{ $t('worker.other_languages') }}</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            :items="language_list"
            :label="$t('worker.other_languages')"
            item-text="item-text"
            item-value="item-value"
            hide-details
            v-model="other_language"
            outlined
            multiple
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0">
          <h4>{{ $t('worker.religion') }}</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            :items="religion_list"
            :label="$t('worker.religion')"
            item-text="item-text"
            item-value="item-value"
            hide-details
            outlined
            multiple
            v-model="religion"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pb-0">
          <h4>{{ $t('customer.interest_hobbies_love') }}</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            :items="interest_list"
            :label="$t('customer.interest_hobbies_love')"
            item-text="item-text"
            item-value="item-value"
            hide-details
            outlined
            multiple
            v-model="interest"
          ></v-select>
        </v-col>
      </v-row>
    </v-card>

    <!-- Medical, Living Needs and Goals -->
    <v-card elevation="0" class="px-6 py-4 mb-8">
      <v-row class="mb-4">
        <v-col>
          <h3>{{ $t('customer.medical_living_needs_goals') }}</h3>
        </v-col>
      </v-row>
      <!-- Medical, Living Needs and Goals -->
      <v-row>
        <v-col class="py-0">
          <h4>{{ $t('customer.medical_living_needs_goals') }}</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select
            :items="medical_living_needs_list"
            :label="$t('customer.medical_living_needs_goals')"
            item-text="item-text"
            item-value="item-value"
            hide-details
            outlined
            multiple
            v-model="medical.support_required"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <!-- Upload Document -->
      <v-row>
        <v-col>
          <h4>{{ $t('customer.care_plan') }}</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex align-center mb-0">
          <v-file-input
            id="care_plan"
            v-show="false"
            @change="getUploadFile($event, 'care_plan')"
            accept="image/*,application/pdf"
            outlined
          ></v-file-input>
          <v-text-field
            type="text"
            outlined
            readonly
            hide-details="auto"
            v-model="medical.care_plan.file"
            :label="$t('general.upload_document')"
          >
          </v-text-field>
          <v-btn color="primary" class="ml-6 mt-2" depressed :loading="submitLoading" @click="selectFile('care_plan')">
            {{ $t('general.upload') }}</v-btn
          >
          <v-btn
            class="ml-4 mt-2"
            color="info"
            depressed
            :loading="submitLoading"
            :disabled="medical.care_plan.disable_preview"
            @click="previewFile(medical.care_plan.file)"
          >
            {{ $t('general.view_detail') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>{{ $t('customer.gp_documents') }}</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex align-center mb-0">
          <v-file-input
            id="gp_documents"
            v-show="false"
            @change="getUploadFile($event, 'gp_documents')"
            accept="image/*,application/pdf"
            outlined
          ></v-file-input>
          <v-text-field
            type="text"
            outlined
            readonly
            hide-details="auto"
            v-model="medical.gp_document.file"
            :label="$t('general.upload_document')"
          >
          </v-text-field>
          <v-btn
            color="primary"
            class="ml-6 mt-2"
            depressed
            :loading="submitLoading"
            @click="selectFile('gp_documents')"
          >
            {{ $t('general.upload') }}</v-btn
          >
          <v-btn
            class="ml-4 mt-2"
            color="info"
            depressed
            :loading="submitLoading"
            :disabled="medical.gp_document.disable_preview"
            @click="previewFile(medical.gp_document.file)"
          >
            {{ $t('general.view_detail') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>{{ $t('customer.other_document') }}</h4>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex align-center mb-0">
          <v-file-input
            id="other_document"
            v-show="false"
            @change="getUploadFile($event, 'other_document')"
            accept="image/*,application/pdf"
            outlined
          ></v-file-input>
          <v-text-field
            type="text"
            outlined
            readonly
            hide-details="auto"
            v-model="medical.other_document.file"
            :label="$t('general.upload_document')"
          >
          </v-text-field>
          <v-btn
            color="primary"
            class="ml-6 mt-2"
            depressed
            :loading="submitLoading"
            @click="selectFile('other_document')"
          >
            {{ $t('general.upload') }}</v-btn
          >
          <v-btn
            class="ml-4 mt-2"
            color="info"
            depressed
            :loading="submitLoading"
            :disabled="medical.other_document.disable_preview"
            @click="previewFile(medical.other_document.file)"
          >
            {{ $t('general.view_detail') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <!-- Instructions for independent support worker -->
      <v-row class="mb-4">
        <v-col>
          <div class="d-flex align-center">
            <h3>{{ $t('customer.instruction_for_support_worker') }}</h3>
            <v-btn class="ml-4" color="accent" depressed small @click="addInstruction">
              {{ $t('general.add') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <div v-for="(instruction, index) in instruction_list" :key="index">
        <v-row>
          <v-col>
            <div class="d-flex align-center">
              <h4>
                {{ $t('customer.instruction') }}
                {{ instruction_list.length > 1 ? index + 1 : '' }}
              </h4>
              <v-btn icon color="error" class="ml-4" @click="removeInstruction(index)">
                <v-icon>{{ icon.delete_icon }}</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              :label="$t('customer.medication')"
              outlined
              hide-details="auto"
              v-model="instruction.medication"
            ></v-text-field>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              :label="$t('customer.dosage')"
              outlined
              hide-details="auto"
              v-model="instruction.dosage"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              :label="$t('customer.frequency')"
              outlined
              hide-details="auto"
              v-model="instruction.frequency"
            ></v-text-field>
          </v-col>
          <v-col xl="6" lg="6" md="6" sm="6" cols="12">
            <v-text-field
              type="text"
              :label="$t('customer.reason_taken')"
              outlined
              hide-details="auto"
              v-model="instruction.reason_taken"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <v-row>
      <v-col>
        <v-btn color="primary" @click="saveChange">{{ $t('general.save') }}</v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mdiCalendar, mdiDelete, mdiChevronUp, mdiChevronDown } from '@mdi/js'
import { FormValidator, CheckInputValidate, globalFunc, MEDIA_PATH } from '@/global'
import { getData, postData } from '@/api'

export default {
  name: 'CustomerDetail',
  mixins: [FormValidator, CheckInputValidate, globalFunc],
  data: () => ({
    FormValidator: FormValidator,
    submitLoading: false,
    icon: {
      calendar_icon: mdiCalendar,
      delete_icon: mdiDelete,
      expand_icon: mdiChevronDown,
      collapse_icon: mdiChevronUp,
    },
    customer_account: {
      id: '',
      email: '',
      create_date: '',
      who_is_seeking_support: '',
      media_to_know: '',
      status: '',
      disable_user_approved: false,
      user_approved: '',
      is_adult: '',
      support_last_name: '',
      support_first_name: '',
    },
    customer_account_list_item: {
      worker_status: [],
      worker_approval_status: [],
      media_to_know: [],
      is_adult: [],
    },
    customer_profile: {
      profile_pic: '',
      disable_preview: true,
      first_name: '',
      last_name: '',
      nickname: '',
      gender: '',
      mobile: '',
      residential_addr: '',
      postal_addr: '',
      medication: '',
      care: [],
      other_detail: '',
      other_detail_err: false,
    },
    gender_list: [],
    date_modal: false,
    date_of_birth: '',
    max_birth_date: '',
    emergency_contact_list: [],
    household: {
      smokers: false,
      equipment_available: false,
      pet: [],
      access_info: '',
      safe_working_env_premises: false,
    },
    type_of_care_need_list: [],
    relationship_list: [],
    participant_detail_list: [],
    participant_detail_date: [],
    participant_detail_date_modal: [],
    store_same_as_account_profile: {},
    store_first_form: {},
    first_language: '',
    cultural_background: '',
    other_language: [],
    religion: [],
    interest: [],
    pet_list: [],
    language_list: [],
    religion_list: [],
    interest_list: [],
    medical_living_needs_list: [],
    medical: {
      support_required: [],
      care_plan: {
        disable_preview: true,
        file: '',
      },
      gp_document: {
        disable_preview: true,
        file: '',
      },
      other_document: {
        disable_preview: true,
        file: '',
      },
    },
    instruction_list: [],
    yes_no_list: [],
  }),
  methods: {
    async initializeData() {
      try {
        let params = new URLSearchParams()
        params.set('get_user_by_id', JSON.stringify({ id: parseInt(this.$route.params.id, 10) }))
        let get_user_data = await getData(params)

        if (get_user_data.user_type == 'worker') {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: `${this.$t('navigation.customer')} ${this.$t('error.does_not_exist')}`,
            type: 'error',
            refresh: false,
            redirect: 'Customer',
          })

          return
        }
        this.$func.log('-----Get User Data-----')
        this.$func.log(get_user_data)

        // Customer Account
        this.customer_account.id = get_user_data.id
        this.customer_account.email = get_user_data.email
        this.customer_account.create_date = globalFunc.getDateStr(get_user_data.create_date)
        this.customer_account.media_to_know = get_user_data.hear_from
        this.customer_account.status = get_user_data.status
        this.customer_account.user_approved = get_user_data.user_approved
        this.customer_account.who_is_seeking_support = get_user_data.support_target
        this.customer_account.is_adult = get_user_data.is_adult
        if (get_user_data.support_target == 'other') {
          this.customer_account.support_last_name = get_user_data.target_last_name
          this.customer_account.support_first_name = get_user_data.target_first_name
        }

        if (get_user_data.request_approve) {
          this.customer_account.disable_user_approved = false
        } else {
          this.customer_account.disable_user_approved = true
        }

        // Customer Profile
        if (globalFunc.DataValid(get_user_data.profile_pic)) {
          this.customer_profile.profile_pic = get_user_data.profile_pic
          this.customer_profile.disable_preview = false
        }

        this.customer_profile.first_name = get_user_data.first_name
        this.customer_profile.last_name = get_user_data.last_name
        this.customer_profile.nickname = get_user_data.nick_name
        this.customer_profile.gender = get_user_data.gender
        this.customer_profile.mobile = get_user_data.mobile
        this.date_of_birth = globalFunc.getDateStr(get_user_data.birthday)
        this.customer_profile.residential_addr = get_user_data.addr
        this.customer_profile.postal_addr = get_user_data.post_addr
        this.customer_profile.medication = get_user_data.need_medication_assistance
        this.customer_profile.care = get_user_data.needed_care
        this.customer_profile.other_detail = get_user_data.specific_care

        // Participant Detail
        let participant = get_user_data.participants

        let account_profile_obj = {
          first_name: get_user_data.first_name,
          last_name: get_user_data.last_name,
          gender: get_user_data.gender,
          date_of_birth: globalFunc.getDateStr(get_user_data.birthday),
          mobile: get_user_data.mobile,
          email: '',
          residential_address: get_user_data.addr,
          postal_address: get_user_data.post_addr,
          allergies_medical_alert: '',
        }

        if (participant.length > 0) {
          for (let i = 0; i < participant.length; i++) {
            let data = participant[i]

            if (data.same_as_ac && i == 0) {
              let obj = {}
              Object.assign(obj, account_profile_obj)
              obj['id'] = data.id
              obj['email'] = data.email
              obj['alert'] = data.alert
              obj['allergies_medical_alert'] = data.alert
              obj['same_as_ac'] = data.same_as_ac

              this.addParticipantDetail(null, obj)
              Object.assign(this.store_first_form, obj)
            } else {
              let temp = {
                id: data.id,
                first_name: data.first_name,
                last_name: data.last_name,
                gender: data.gender,
                date_of_birth: globalFunc.getDateStr(data.birthday),
                mobile: data.mobile,
                email: data.email,
                residential_address: data.addr,
                postal_address: data.post_addr,
                allergies_medical_alert: data.alert,
                same_as_ac: data.same_as_ac,
              }

              this.addParticipantDetail(null, temp)
            }
          }
        } else {
          this.addParticipantDetail(null, account_profile_obj)
          Object.assign(this.store_same_as_account_profile, this.participant_detail_list[0])
        }

        // Emergency Contact
        let emergency = get_user_data.emergency
        this.$func.log(get_user_data.emergency)
        if (emergency.length > 0) {
          for (let i = 0; i < emergency.length; i++) {
            let data = emergency[i]
            let temp = {
              id: data.id,
              name: data.name,
              mobile: data.mobile,
              email: data.email,
              relationship: data.relationship,
              emergency_response: data.other_info,
            }
            this.addEmergencyContact(null, temp)
          }
        } else {
          this.addEmergencyContact(null)
        }

        // Household
        this.household.smokers = get_user_data.smokers
        this.household.equipment_available = get_user_data.equipment_available
        this.household.pet = get_user_data.pet
        this.household.access_info = get_user_data.access_info
        this.household.safe_working_env_premises = get_user_data.safe_working_env_premises

        // Social Needs and Goals
        this.first_language = get_user_data.first_language
        this.cultural_background = get_user_data.cultural
        this.other_language = get_user_data.other_languages
        this.religion = get_user_data.religion
        this.interest = get_user_data.hobbies

        // Medical, Living Needs and Goals
        this.medical.support_required = get_user_data.support_required
        this.medical.care_plan.file = get_user_data.care_plan_file
        if (globalFunc.DataValid(get_user_data.care_plan_file)) {
          this.medical.care_plan.disable_preview = false
        }

        this.medical.gp_document.file = get_user_data.gp_document
        if (globalFunc.DataValid(get_user_data.gp_document)) {
          this.medical.gp_document.disable_preview = false
        }

        this.medical.other_document.file = get_user_data.other_document
        if (globalFunc.DataValid(get_user_data.other_document)) {
          this.medical.other_document.disable_preview = false
        }

        let instruction = get_user_data.instructions
        for (let i = 0; i < instruction.length; i++) {
          this.addInstruction(instruction[i])
        }
      } catch (error) {
        this.$func.log(error)
        if (Array.isArray(error)) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: `${this.$t('navigation.customer')} ${this.$t('error.does_not_exist')}`,
            type: 'error',
            refresh: false,
            redirect: 'Customer',
          })
        }
      } finally {
        this.$store.dispatch('toggleLoadingPage', false)
      }
    },
    initializeStatus() {
      this.customer_account_list_item.worker_status = [
        { 'item-text': this.$t('status.active'), 'item-value': 'active' },
        { 'item-text': this.$t('status.inactive'), 'item-value': 'inactive' },
      ]

      this.customer_account_list_item.worker_approval_status = [
        { 'item-text': this.$t('status.approved'), 'item-value': true },
        { 'item-text': this.$t('status.pending'), 'item-value': false },
      ]

      this.customer_account_list_item.is_adult = [
        { 'item-text': this.$t('general.yes'), 'item-value': true },
        { 'item-text': this.$t('general.no'), 'item-value': false },
      ]

      this.customer_account_list_item.media_to_know = [
        { 'item-text': this.$t('media_to_know.facebook'), 'item-value': 'Facebook' },
        { 'item-text': this.$t('media_to_know.instagram'), 'item-value': 'Instagram' },
        { 'item-text': this.$t('media_to_know.google_search'), 'item-value': 'Google Search' },
        { 'item-text': this.$t('media_to_know.newspaper'), 'item-value': 'Newspaper' },
        { 'item-text': this.$t('media_to_know.refer_by_friends'), 'item-value': 'Refer by Friends' },
        { 'item-text': this.$t('media_to_know.others'), 'item-value': 'Others' },
      ]

      this.gender_list = [
        { 'item-text': this.$t('gender.male'), 'item-value': 'Male' },
        { 'item-text': this.$t('gender.female'), 'item-value': 'Female' },
        {
          'item-text': this.$t('gender.not_to_say'),
          'item-value': 'Prefer not to say',
        },
      ]

      this.type_of_care_need_list = [
        {
          text: this.$t('type_of_care_list.disability_support'),
          value: 'Disability Support',
        },
        {
          text: this.$t('type_of_care_list.aged_care'),
          value: 'Aged Care',
        },
        {
          text: this.$t('type_of_care_list.mental_health'),
          value: 'Mental Health',
        },
        {
          text: this.$t('type_of_care_list.post_surgery'),
          value: 'Post-surgery',
        },
        {
          text: this.$t('type_of_care_list.other'),
          value: 'Other',
        },
      ]

      this.relationship_list = [
        {
          'item-text': this.$t('relationship.partner_spouse'),
          'item-value': 'Partner/ Spouse',
        },
        {
          'item-text': this.$t('relationship.immediate_family'),
          'item-value': 'Immediate Family',
        },
        {
          'item-text': this.$t('relationship.extended_family'),
          'item-value': 'Extended Family',
        },
        {
          'item-text': this.$t('relationship.legal_representative_advocate'),
          'item-value': 'Legal Representative/ Advocate',
        },
        {
          'item-text': this.$t('relationship.approved_provider_care_coordinator'),
          'item-value': 'Approved Provider/ Care Coordinator',
        },
        {
          'item-text': this.$t('relationship.friend_neighbour'),
          'item-value': 'Friend/ Neighbour',
        },
      ]

      this.pet_list = [
        {
          'item-text': this.$t('customer.dog'),
          'item-value': 'Dog',
        },
        {
          'item-text': this.$t('customer.cat'),
          'item-value': 'Cat',
        },
        {
          'item-text': this.$t('customer.other_pets'),
          'item-value': 'Other pets',
        },
      ]

      this.language_list = [
        {
          'item-text': this.$t('first_language_list.cantonese'),
          'item-value': 'Cantonese',
        },
        {
          'item-text': this.$t('first_language_list.english'),
          'item-value': 'English',
        },
        {
          'item-text': this.$t('first_language_list.mandarin'),
          'item-value': 'Mandarin',
        },
        {
          'item-text': this.$t('first_language_list.arabic'),
          'item-value': 'Arabic',
        },
        {
          'item-text': this.$t('first_language_list.french'),
          'item-value': 'French',
        },
        {
          'item-text': this.$t('first_language_list.german'),
          'item-value': 'German',
        },
        {
          'item-text': this.$t('first_language_list.indonesian'),
          'item-value': 'Indonesian',
        },
        {
          'item-text': this.$t('first_language_list.italian'),
          'item-value': 'Italian',
        },
        {
          'item-text': this.$t('first_language_list.japanese'),
          'item-value': 'Japanese',
        },
        {
          'item-text': this.$t('first_language_list.korean'),
          'item-value': 'Korean',
        },
        {
          'item-text': this.$t('first_language_list.spanish'),
          'item-value': 'Spanish',
        },
        {
          'item-text': this.$t('first_language_list.music'),
          'item-value': 'Music',
        },
        {
          'item-text': this.$t('first_language_list.vietnamese'),
          'item-value': 'Vietnamese',
        },
      ]

      this.religion_list = [
        {
          'item-text': this.$t('religion_list.buddhism'),
          'item-value': 'Buddhism',
        },
        {
          'item-text': this.$t('religion_list.taoism'),
          'item-value': 'Taoism',
        },
        {
          'item-text': this.$t('religion_list.confucianism'),
          'item-value': 'Confucianism',
        },
        {
          'item-text': this.$t('religion_list.catholic'),
          'item-value': 'Catholic',
        },
        {
          'item-text': this.$t('religion_list.christian'),
          'item-value': 'Christian',
        },
        {
          'item-text': this.$t('religion_list.muslim'),
          'item-value': 'Muslim',
        },
        {
          'item-text': this.$t('religion_list.hindu'),
          'item-value': 'Hindu',
        },
        {
          'item-text': this.$t('religion_list.sikh'),
          'item-value': 'Sikh',
        },
        {
          'item-text': this.$t('religion_list.other'),
          'item-value': 'Other religion',
        },
        {
          'item-text': this.$t('religion_list.no_religion'),
          'item-value': 'No religion',
        },
      ]

      this.interest_list = [
        {
          'item-text': this.$t('interest_list.cooking'),
          'item-value': 'Cooking',
        },
        {
          'item-text': this.$t('interest_list.pets'),
          'item-value': 'Pets',
        },
        {
          'item-text': this.$t('interest_list.cultural_festivities'),
          'item-value': 'Cultural Festivities',
        },
        {
          'item-text': this.$t('interest_list.photography'),
          'item-value': 'Photography',
        },
        {
          'item-text': this.$t('interest_list.art'),
          'item-value': 'Art',
        },
        {
          'item-text': this.$t('interest_list.reading'),
          'item-value': 'Reading',
        },
        {
          'item-text': this.$t('interest_list.indoor_games'),
          'item-value': 'Indoor Games',
        },
        {
          'item-text': this.$t('interest_list.puzzles'),
          'item-value': 'Puzzles',
        },
        {
          'item-text': this.$t('interest_list.sports'),
          'item-value': 'Sports',
        },
        {
          'item-text': this.$t('interest_list.movies'),
          'item-value': 'Movies',
        },
        {
          'item-text': this.$t('interest_list.travel'),
          'item-value': 'Travel',
        },
        {
          'item-text': this.$t('interest_list.music'),
          'item-value': 'Music',
        },
        {
          'item-text': this.$t('interest_list.other'),
          'item-value': 'Other',
        },
      ]

      this.medical_living_needs_list = [
        {
          'item-text': this.$t('medical_living_needs_goals.cardiac_vascular'),
          'item-value': 'Cardiac / Vascular',
        },
        {
          'item-text': this.$t('medical_living_needs_goals.diabetes_cholesterol'),
          'item-value': 'Diabetes / Cholesterol',
        },
        {
          'item-text': this.$t('medical_living_needs_goals.incontinence'),
          'item-value': 'Incontinence (bladder or bowel)',
        },
        {
          'item-text': this.$t('medical_living_needs_goals.memory_loss_dementia'),
          'item-value': 'Memory Loss / Dementia',
        },
        {
          'item-text': this.$t('medical_living_needs_goals.nutrition_hydration'),
          'item-value': 'Nutrition / Hydration',
        },
        {
          'item-text': this.$t('medical_living_needs_goals.other_barriers_or_concerns'),
          'item-value': 'Other barriers or concerns',
        },
        {
          'item-text': this.$t('medical_living_needs_goals.physical_mobility'),
          'item-value': 'Physical / Mobility',
        },
        {
          'item-text': this.$t('medical_living_needs_goals.psychological_behavioural'),
          'item-value': 'Psychological / Behavioural',
        },
        {
          'item-text': this.$t('medical_living_needs_goals.skin_integrity'),
          'item-value': 'Skin Integrity / Wound Management',
        },
        {
          'item-text': this.$t('medical_living_needs_goals.speech_swallowing'),
          'item-value': 'Speech / Swallowing',
        },
      ]

      this.yes_no_list = [
        { 'item-text': this.$t('general.yes'), 'item-value': true },
        { 'item-text': this.$t('general.no'), 'item-value': false },
      ]
    },
    addEmergencyContact(e, obj = null) {
      this.$func.log(obj)
      if (obj != null) {
        this.emergency_contact_list.push(obj)
      } else {
        this.emergency_contact_list.push({
          name: '',
          mobile: '',
          email: '',
          relationship: '',
          emergency_response: '',
        })
      }
    },
    removeEmergencyContact(index) {
      this.emergency_contact_list.splice(index, 1)
    },
    addParticipantDetail(e, obj = null) {
      if (obj != null) {
        this.participant_detail_list.push(obj)
        this.participant_detail_date.push(obj.date_of_birth)
      } else {
        let temp = {
          first_name: '',
          last_name: '',
          gender: '',
          date_of_birth: '',
          mobile: '',
          email: '',
          residential_address: '',
          postal_address: '',
          allergies_medical_alert: '',
        }

        if (this.participant_detail_list.length == 0) {
          temp.same_as_ac = true
        } else {
          temp.same_as_ac = false
        }
        this.participant_detail_list.push(temp)
        this.participant_detail_date.push('')
      }

      this.participant_detail_date_modal.push(false)
    },
    removeParticipantDetail(index) {
      this.participant_detail_list.splice(index, 1)
      this.participant_detail_date.splice(index, 1)
      this.participant_detail_date_modal.splice(index, 1)
    },
    toggleFirstDetail() {
      let check = this.participant_detail_list[0].same_as_ac
      if (check) {
        // from false to true
        Object.assign(this.store_first_form, this.participant_detail_list[0])
        Object.assign(this.participant_detail_list[0], this.store_same_as_account_profile)
      } else {
        // from true to false
        Object.assign(this.store_same_as_account_profile, this.participant_detail_list[0])
        Object.assign(this.participant_detail_list[0], this.store_first_form)
      }

      this.participant_detail_date[0] = this.participant_detail_list[0].date_of_birth
      this.participant_detail_list[0].same_as_ac = check

      this.$func.log(this.store_same_as_account_profile)
      this.$func.log(this.store_first_form)
    },
    addInstruction(obj = null) {
      if (obj != null) {
        this.instruction_list.push(obj)
      } else {
        this.instruction_list.push({
          medication: '',
          dosage: '',
          frequency: '',
          reason_taken: '',
        })
      }
    },
    removeInstruction(index) {
      this.instruction_list.splice(index, 1)
    },
    selectFile(id) {
      document.getElementById(id).click()
    },
    async getUploadFile(file, type, index = -1) {
      if (globalFunc.DataValid(file)) {
        if (type == 'profile_photo') {
          if (file.type.indexOf('image/') == -1) {
            this.submitLoading = false

            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: `${this.$t('error.force_upload')} ${this.$t('error.image_file')}`,
              type: 'error',
              refresh: false,
              redirect: '',
            })

            return
          }
        } else {
          if (file.type.indexOf('image/') == -1 && file.type != 'application/pdf') {
            this.submitLoading = false

            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: `${this.$t('error.force_upload')} ${this.$t('error.image_pdf_file')}`,
              type: 'error',
              refresh: false,
              redirect: '',
            })
            return
          }
        }

        this.submitLoading = true
        try {
          let base64Path = await globalFunc.firstFileToBase64(file)
          let file_type = file.name.substring(file.name.lastIndexOf('.') + 1)
          let params = new URLSearchParams()
          params.set(
            'upload_file',
            JSON.stringify({
              upload_data: base64Path,
              upload_file_type: file_type,
            }),
          )

          let upload_file = await postData(params)
          this.$func.log('--- upload success ---')
          this.$func.log(upload_file)

          if (type == 'profile_photo') {
            this.customer_profile.profile_pic = upload_file
            this.customer_profile.disable_preview = false
          } else if (type == 'care_plan') {
            this.medical.care_plan.disable_preview = false
            this.medical.care_plan.file = upload_file
          } else if (type == 'gp_documents') {
            this.medical.gp_document.disable_preview = false
            this.medical.gp_document.file = upload_file
          } else if (type == 'other_document') {
            this.medical.other_document.disable_preview = false
            this.medical.other_document.file = upload_file
          }

          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: this.$t('instruction.upload_success'),
            type: 'success',
            refresh: false,
            redirect: '',
          })
        } catch (error) {
          this.$func.log(error)
          this.$func.log('--- upload fail ---')
          this.$func.log(data)

          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: this.$t('instruction.upload_fail'),
            type: 'error',
            refresh: false,
            redirect: '',
          })
        } finally {
          this.submitLoading = false
        }
      } else {
        this.submitLoading = false

        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: `${this.$t('error.force_upload')} ${this.$t('error.file')}`,
          type: 'error',
          refresh: false,
          redirect: '',
        })
      }
    },
    previewFile(fileName) {
      if (globalFunc.DataValid(fileName)) {
        let link = MEDIA_PATH + fileName
        window.open(link, '_blank')
      } else {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: this.$t('instruction.file_not_found'),
          type: 'error',
          refresh: false,
          redirect: '',
        })
      }
    },
    customValidate() {
      let validate = true

      if (this.customer_profile.care.length == 0) {
        validate = false
      }

      if (this.customer_profile.care.includes('Other') && !globalFunc.DataValid(this.customer_profile.other_detail)) {
        validate = false
        this.customer_profile.other_detail_err = true
      } else {
        this.customer_profile.other_detail_err = false
      }

      return validate
    },
    async saveChange() {
      let form_validate = this.$refs.form.validate()
      let custom_validate = this.customValidate()
      if (!form_validate || !custom_validate) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: this.$t('error.fill_all_required_field'),
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      try {
        this.submitLoading = true
        let send_data = {
          id: parseInt(this.$route.params.id, 10),
          user_type: 'customer',
        }

        // Customer Account
        send_data.is_adult = this.customer_account.is_adult
        send_data.hear_from = this.customer_account.media_to_know
        send_data.status = this.customer_account.status
        send_data.user_approved = this.customer_account.user_approved
        if (this.customer_account.who_is_seeking_support == 'other') {
          send_data['target_last_name'] = this.customer_account.support_last_name
          send_data['target_first_name'] = this.customer_account.support_first_name
        }

        // Customer Profile
        send_data.profile_pic = this.customer_profile.profile_pic
        send_data.first_name = this.customer_profile.first_name
        send_data.last_name = this.customer_profile.last_name
        send_data.nick_name = this.customer_profile.nickname
        send_data.gender = this.customer_profile.gender
        send_data.mobile = this.customer_profile.mobile
        send_data.birthday = this.date_of_birth
        send_data.addr = this.customer_profile.residential_addr
        send_data.post_addr = this.customer_profile.postal_addr
        send_data.need_medication_assistance = this.customer_profile.medication
        send_data.needed_care = this.customer_profile.care
        send_data.specific_care = this.customer_profile.other_detail

        // Participant
        let participant = []
        for (let i = 0; i < this.participant_detail_list.length; i++) {
          let data = this.participant_detail_list[i]

          let temp = {
            first_name: data.first_name,
            last_name: data.last_name,
            gender: data.gender,
            birthday: this.participant_detail_date[i],
            mobile: data.mobile,
            email: data.email,
            addr: data.residential_address,
            post_addr: data.postal_address,
            alert: data.allergies_medical_alert,
            same_as_ac: data.same_as_ac,
          }

          if (globalFunc.DataValid(data.id)) {
            temp.id = data.id
          }

          participant.push(temp)
        }

        send_data.participants = participant

        // Emergency Contact
        let emergency = []
        for (let i = 0; i < this.emergency_contact_list.length; i++) {
          let data = this.emergency_contact_list[i]

          let temp = {
            name: data.name,
            mobile: data.mobile,
            email: data.email,
            relationship: data.relationship,
            other_info: data.emergency_response,
          }

          if (globalFunc.DataValid(data.id)) {
            temp.id = data.id
          }

          emergency.push(temp)
        }

        send_data.emergency = emergency

        // Household
        send_data.smokers = this.household.smokers
        send_data.equipment_available = this.household.equipment_available
        send_data.pet = this.household.pet
        send_data.access_info = this.household.access_info
        send_data.safe_working_env_premises = this.household.safe_working_env_premises

        //Social Needs and Goals
        send_data.first_language = this.first_language
        send_data.cultural = this.cultural_background
        send_data.other_languages = this.other_language
        send_data.religion = this.religion
        send_data.hobbies = this.interest

        // Medical, Living Needs and Goals
        send_data.support_required = this.medical.support_required
        send_data.care_plan_file = this.medical.care_plan.file
        send_data.gp_document = this.medical.gp_document.file
        send_data.other_document = this.medical.other_document.file

        send_data.instructions = this.instruction_list

        let params = new URLSearchParams()
        params.set('update_user', JSON.stringify(send_data))
        let update_user = await postData(params)

        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: this.$t('instruction.update_success'),
          type: 'success',
          refresh: false,
          redirect: 'Customer',
        })
      } catch (error) {
        this.$func.log(error)
        this.submitLoading = false
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: this.$t('instruction.update_fail'),
          type: 'error',
          refresh: false,
          redirect: '',
        })
      }
    },
    removeFile() {
      this.customer_profile.profile_pic = ''
      this.customer_profile.disable_preview = true
    },
  },
  async created() {
    const fetchAllow = await this.checkToken()
    if (fetchAllow) {
      this.max_birth_date = globalFunc.formatDate(new Date())
      this.initializeData()
      this.initializeStatus()
    }
  },
}
</script>